import React, { useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import { Selectpaymentmethod } from '../Selectpaymentmethod';
import { Customersubscriptions } from './Customersubscriptions';
import {useSelector, useDispatch} from 'react-redux'
import { unbooked, booked } from '../../store/bookedSlice';
import { renderMessage } from '../../services/stateServices';

export const Customerinfo = (props) => {
    
    const customer = useSelector(state => state.customer.value)
    const dispatch = useDispatch();

    useEffect(
        () => {
            if(customer.id === 0) {
                renderMessage({msg:'In der Komponente Customerinfo gab es einen Fehler beim Abruf der Kundendaten'},'alert-danger', false);
            }

            if(customer.has_subscription) {
                dispatch(booked())
            }
        }
    );
    if(customer.id === 0) {
        return null;
    }
    return (
        <>
             <Card className='w-100 p-5'>
                <Card.Body>
                    <Card.Title>{customer.name}</Card.Title>
                    <Card.Text>
                    {customer.address.line1} <br />
                    {customer.address.postal_code} {customer.address.city} <br /><br />
                
                    </Card.Text>
                    <Selectpaymentmethod headline="Angelegte Bezahlmethoden" /><br /><br />
                    <Customersubscriptions  headline="Bestehende Mitgliedschaften" variant="default"/>
                </Card.Body>
            
            </Card>

            
        </>


    )
    
}
