import React, { useEffect, useState } from 'react';
import { Customersubscriptions } from '../customer/Customersubscriptions';
import { Spinnercomponent } from '../Spinnercomponent';
import { Product } from './Product';
import {api} from '../../services/api';
import { renderMessage } from '../../services/stateServices';
import {useSelector, useDispatch} from 'react-redux'

export const Productslist = ({headline}) => {

    const [prices, setPrices] = useState({});
    const [loaded, setLoaded] = useState(false);
    const booked_val = useSelector((state) => state.booked.value); //gibt es bereits eine Buchung, werden (derzeit noch) weitere Buchungsmöglichkeiten disabled
    



    useEffect(() => {
        api.getPrices().then(
            price_obj => {
                if(price_obj.error === 1) {
                    renderMessage({msg:'In der Komponente Productslist gab es einen Fehler beim Abruf der Preise'},'alert-danger', false);
                } else {
                    setPrices(price_obj);
                    setLoaded(true);
                }
     
            }
        ).catch(error => { 
            setPrices(error);
        }); 
    },[loaded]);

    //Reload on booked / unbooked dispatch
    useEffect(() => {
        setLoaded(false)
    },[booked_val])

    
    let products = [];


    if(!loaded) {
        return <Spinnercomponent />
    } else {
        products = prices.data.map(element => {
            return <Product price={element} key={element.id} disabled={booked_val===true}/>
        })
    }

    return (
        <>
        <Customersubscriptions headline="Bestehende Mitgliedschaften" />
          <h3>{headline}</h3>
         {products}
        </>
    )
}