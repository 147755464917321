import React, { useEffect, useState } from 'react';
import { Customerinfo } from './Customerinfo';

export const Customer = ({headline}) => {

    return (
        <>
          <h3>{headline}</h3>
          <Customerinfo />
        </>
    )
    
}
