import React, { useEffect, useState } from 'react';

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

import { Spinnercomponent } from './Spinnercomponent';
import { Preloader } from './Preloader';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { Paymentmethods } from './Paymentmethods';
import { Customer } from './customer/Customer';

import { ErrorBoundary } from './ErrorBoundary';
import { Errorbox } from './Errorbox';
import { Messagebox } from './Messagebox';
import { Tabnav } from './tabs/tabnav';
import { Tabcontent } from './tabs/tabcontent';

//services
import { api } from '../services/api';
import { triggerClick } from '../services/stateServices'; 
import { Productslist } from './products/Productslist';
import { Invoices } from './invoices/Invoices';



/* needed in Elements Provider */
const stripePromise = loadStripe(api.pk_stripe);


export const Stripecontainer = ({headline}) => { 
   
    const [options, setOptions] = useState({clientSecret:0});
    const [current_tab, setCurrentTab] = useState(0);


    useEffect(()=>{
       
        api.getSetupIntent().then(
            intent_obj => {
                setOptions({clientSecret: intent_obj.client_secret});   
            }
        );

    },[]);


 
    useEffect(() => {
       /* if(api.getVar('payment_method_success')==1){
           triggerClick('#payment') } */
        if(!location.hash){
            triggerClick('#home') 
        } else {
            triggerClick(location.hash) 
        }
    }, [options])
    

    const handleCurrentTab = (chosen_tab) => {
        setCurrentTab(chosen_tab)
    }


    if(options.clientSecret === 0) {
        return <Spinnercomponent />;
    }

    return (
        <>
        
            <Elements stripe={stripePromise} options={options}>
                <Preloader />
                <Container>
                     <Row>
                         <Col>
                             <h1>{headline}</h1>
                         </Col>
                     </Row>
                     <Row>
                        <Col>
                        <div className="alert alert-info">Dies ist nur eine Testversion, keinerlei Buchung wird ausgeführt. Außerdem befindet sich die Anwendung "immer" im Umbau ;-)<br/><a href="https://stripe.com/docs/testing?locale=de-DE&testing-method=card-numbers#cards" target="_blank">Kreditkartennummern zum Testen</a> | <a href="https://stripe.com/docs/testing#zahlungen-ohne-karte" target="_blank">Testzahlungen ohne Karte</a></div>
      </Col>
                     </Row>
                     <Errorbox />   
                     <Messagebox /> 

                    <Row>
                        <Col>
                            <Container className='p-0' id='tabcontainer'>

                                <Row>
                                    <Col>
                                     <Tabnav handleCurrentTab={handleCurrentTab}/>
                                    </Col>
                                </Row>


                                <Tabcontent current_tab={current_tab}>

                                    <Row>
                                        <Col>
                                            <ErrorBoundary message="Es gab ein Problem mit der Kundenkomponente">
                                                <Customer headline="Übersicht Kunde" />
                                            </ErrorBoundary>    
                                        </Col>
                                    </Row>


                                    <Row>
                                        <Col>
                                        <ErrorBoundary message="Es gab ein Problem mit den Bezahlmethoden">
                                            <Paymentmethods headline="Verfügbare Bezahlmethoden"/>
                                        </ErrorBoundary>
                                        
                                        </Col>
                                    </Row>



                                    <Row>
                                        <Col>
                                        <ErrorBoundary message="Es gab ein Problem in der Produktliste">
                                            <Productslist headline="Produkte" />
                                        </ErrorBoundary>
                                        </Col>
                                    </Row>


                                    <Row>
                                        <Col>
                                        <ErrorBoundary message="Es gab ein Problem in der Rechnungskomponente">
                                            <Invoices headline="Rechnungen" />
                                        </ErrorBoundary>
                                        </Col>
                                    </Row>

                                </Tabcontent>

                            </Container>
                        </Col>
                    </Row>    


                    
                </Container>
             </Elements>   
       </>
    )
    
}
