import React, { useEffect, useState } from 'react';
import { Spinnercomponent } from './Spinnercomponent';
import {renderCustomerPaymentMethod } from '../services/stateServices';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { api } from '../services/api';
import {useSelector, useDispatch} from 'react-redux'
import { get_stripe_customer } from '../store/customerSlice'

export const Selectpaymentmethod = ({headline}) => {

    const [list, setList] = useState(<option></option>);
    const [is_default, setDefault] = useState(true);
    const [selected_method, setMethod] = useState(null); //select box customers payment methods
    const [detached_method, setDetached] = useState(null); //select box customers payment methods
    const [loaded, setLoaded] = useState(false);

    let default_button;

    const customer = useSelector((state) => state.customer.value);
    const dispatch = useDispatch();


    useEffect(()=>{  
        //set pre-selected payment method on pageload
        if(customer.invoice_settings.default_payment_method != null) {
            setMethod(customer.invoice_settings.default_payment_method);   
        } else {
            if(customer.paymentmethods) {
                setMethod(customer.paymentmethods.data[0].id);      
            } 
        }

        setLoaded(true);
    },[]);


    useEffect(()=>{     
        let paymentmethodslist = customer.paymentmethods.data.map(
            element => {
                if(element.type === 'card' || element.type === 'sepa_debit' ) {
                     return <option key={element.id} value={element.id}>{renderCustomerPaymentMethod(element,customer.invoice_settings.default_payment_method)}</option>
                }
            }
        )
        setList(paymentmethodslist);
        setLoaded(true);        
    },[selected_method, customer])

    useEffect(() => {
        if(customer.invoice_settings.default_payment_method === selected_method){
            setDefault(true);
        } else {
            setDefault(false);
        }
    },[list]);

    




    /* fn */
    const handlePaymentmethodSelectChange = (e) => {
        let new_value = e.target.value;
        setMethod(new_value); 
    }



    const handlePaymentMethodDetachment = (method_to_detach) => {
       setLoaded(false); 

       //update user on stripe 
       api.detachCustomersPaymentMethod(method_to_detach).then(
        data => {
            setDetached(method_to_detach);

             //update user in store
             dispatch(get_stripe_customer()). then(
                data=>setLoaded(true)
            )}
        )
    }



    const handleSetDefaultPaymentMethod = (default_method) => {
        setLoaded(false); 

        //update user on stripe
        api.updateUsersDefaultPaymentMethodFromSelect(default_method).then(
            data => {
                setMethod(default_method);

                //update user in store
                dispatch(get_stripe_customer()). then(
                    data=>setLoaded(true)
                )}
           )
    }




    

    if(!loaded) {
        return <Spinnercomponent />
    }


    /*
    button should be disabled, when default payment method is selected
    */
    if(list.length) {
        default_button =<Button variant="primary" size="sm" disabled={is_default} onClick={e => handleSetDefaultPaymentMethod(selected_method)}>Zahlungsmittel als Standard festlegen</Button>
    }

    return (
        <>
         <h3>{headline}</h3> 
            <form>
            <Form.Select aria-label="Chose a payment method for payment process"
                onChange = {handlePaymentmethodSelectChange}
                value = {selected_method}
                >
                {list}
            </Form.Select>
            <br />
            {default_button} {' '} <Button variant="danger" size="sm" onClick={() => handlePaymentMethodDetachment(selected_method)}>Zahlungsmittel entfernen</Button>
            </form>
         </>
    )
    
}
