import React, { useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

export const Invoice = ({invoice}) => {

    const handleDownload = () => {
        window.open(invoice.invoice_pdf)
    }

    if(invoice.number){
    return (
        <>
        {console.log(invoice)}
        <Card className='w-100 p-5 single-product-card'>
            <Card.Body>
                <Card.Title>{invoice.number}</Card.Title>
                <Card.Text>
                {invoice.lines.data[0].description}
                <br/><br/> 
                <Button variant="info" size="md" onClick={handleDownload}>Download</Button> 
                </Card.Text>
           
            </Card.Body>
        
        </Card>

       

        
    </>
    )
    }

   return null; 
}