import React, { useEffect, useState, lazy, Suspense } from 'react';
import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';

import { useSelector, useDispatch } from 'react-redux'
import { Selectpaymentmethod } from './Selectpaymentmethod';

export const Paymentmethods = ({headline}) => {
    
    const stripe = useStripe();
    const elements = useElements();
    const redux_customer = useSelector(state => state.customer.value)
    
    const handleSubmit = async (e) => {
        e.preventDefault();


        stripe.confirmSetup({
            elements,
            confirmParams: {
              // Return URL where the customer should be redirected after the SetupIntent is confirmed.
              return_url: 'https://stripe-backend:8890/stripe_subscriptions_react/dist/?setupconfirmed=1',
            },
          })
          .then(function(result) {
           
            if(result.error) {
              let error = result.error
              // Inform the customer that there was an error.
              console.log(`Fehler ${error}`);
             
            } 
          });
    }
   
    const handlePaymentElementChange = (e) => {
        console.log(e.value)
    }

    return (
    <>  
      <Selectpaymentmethod headline="Angelegte Bezahlmethoden" />
      <br/><br/>

      <h3>{headline}</h3>
        <form>
            <PaymentElement 
                onChange = {handlePaymentElementChange}
                layout ={"accordion"}
            />
            <button onClick = {handleSubmit}>Zahlungsmethode anlegen</button>
        </form>
    </> 
     
    )
    
}
