/*
Adapter 
lädt die Daten aus dem Backend und gibt sie über
ein Modul zurück
*/

export const adapter = (function() {

    /**
     * the backend ajax api
     */

    const config = {
        ajaxurl: 'https://wpdev.hallohalle.de/wp-admin/admin-ajax.php'
    }


    const pk_stripe = 'pk_test_51HAsLFFrOhMwejIpPLdtLjGXijN5maq8tlsq4m09vq4PYubYLRvAcdDxD0qlPUiJ7mltsd1nq3xW820WCatocBo200VzdVrKhe';

    /**
     * 
     * @param {*} variable 
     * @returns value of get variable
     * 
     * usage: getQueryVariable("variable")
     */
    function getQueryVariable(variable) {
        var query = window.location.search.substring(1);
        var vars = query.split("&");
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split("=");
            if (pair[0] == variable) { return pair[1]; }
        }
        return (false);
    }

    /**
     * Ajax Request to the ajax api
     * @param {*} data 
     * @returns Promise 
     */
    function fetchData(data) {
        return new Promise((resolve, reject) => {

            var xhr = new XMLHttpRequest();
            xhr.open('POST', config.ajaxurl, true);
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');

            xhr.onload = function() {
                if (xhr.status != 200) {
                    reject({ id: 0, error: 1, errortype: 'http_error', status: xhr.status, msg: xhr.statusText });
                } else {
                    resolve(this.response);
                }
            }

            xhr.send(data);

        });

    }

    function handleFetch(data) {
        return new Promise((resolve, reject) => {
            fetchData(data).then(
                data => {
                    let result_object = JSON.parse(data);
                    resolve(result_object);
                }
            ).catch(error => {
                reject(error);
            });
        });

    }




    async function getStripeCustomer() {
        let customer = getStripeCustomerID();
        let data = 'action=get_stripe_customer&stripe_customer_id=' + customer;

        let result;

        try {
            result = await handleFetch(data);
        } catch (error) {
            result = error;
        } finally {
            return result;
        }
    }



    async function getCustomersPaymentMethods() {
        let customer = getStripeCustomerID();
        let data = 'action=get_customers_payment_methods&stripe_customer_id=' + customer;
        let result;

        try {
            result = await handleFetch(data);
        } catch (error) {
            result = error;
        } finally {
            return result;
        }
    }



    async function cancelSubscription(subscription) {
        let customer = getStripeCustomerID();
        let data = 'action=cancel_subscription&stripe_customer_id=' + customer + '&subscription=' + subscription;

        let result;

        try {
            result = await handleFetch(data);
        } catch (error) {
            result = error;
        } finally {
            return result;
        }
    }


    async function createSubscription(price, collection_method) {
        let customer = getStripeCustomerID();
        let data = 'action=create_subscription&stripe_customer_id=' + customer + '&price=' + price.id + '&collection_method=' + collection_method;

        let result;

        try {
            result = await handleFetch(data);
        } catch (error) {
            result = error;
        } finally {
            return result;
        }
    }

    async function getCustomersInvoices() {
        let customer = getStripeCustomerID();
        let data = 'action=get_invoices&stripe_customer_id=' + customer;
        let result;

        try {
            result = await handleFetch(data);
        } catch (error) {
            result = error;
        } finally {
            return result;
        }
    }


    async function detachCustomersPaymentMethod(payment_method) {
        let customer = getStripeCustomerID();
        let data = 'action=detach_customers_payment_method&stripe_customer_id=' + customer + '&payment_method_id=' + payment_method;

        let result = await handleFetch(data);
        return result;
    }

    async function updateUsersDefaultPaymentMethod() {
        let customer = getStripeCustomerID();

        if (getQueryVariable('setupconfirmed') != 1) {
            return false;
        }
        let seti = getQueryVariable('setup_intent');

        let data = 'action=set_customers_default_payment_method&stripe_customer_id=' + customer + '&seti=' + seti;

        let result = await handleFetch(data);

        location.href = "https://stripe-backend:8890/stripe_subscriptions_react/dist/?payment_method_success=1"

        return result;
    }

    async function updateUsersDefaultPaymentMethodFromSelect(payment_method) {
        let customer = getStripeCustomerID();

        let data = 'action=set_customers_default_payment_method_only&stripe_customer_id=' + customer + '&payment_method=' + payment_method;

        let result = await handleFetch(data);

        return result;
    }


    async function getPrices() {
        let data = 'action=get_prices';
        let result;

        try {
            result = await handleFetch(data);
        } catch (error) {
            result = error;
        } finally {
            return result;
        }
    }


    async function getCurrentSubscriptions() {
        let customer = getStripeCustomerID();
        let data = 'action=get_subscriptions&stripe_customer_id=' + customer;
        let result;

        try {
            result = await handleFetch(data);
        } catch (error) {
            result = error;
        } finally {
            return result;
        }
    }

    async function hasSubscriptions() {
        let customer = getStripeCustomerID();
        let data = 'action=get_subscriptions&stripe_customer_id=' + customer;
        let result;

        try {
            result = await handleFetch(data);
        } catch (error) {
            result = error;
        } finally {
            if (result.data.length) {
                return true;
            }
            return false;
        }
    }


    /**
     * 
     * @returns stripe user id
     */
    function getStripeCustomerID() {
        let mockup_user = 'cus_MUpNwRCk33l9ku';
        return mockup_user;
    }

    function getPaymentMethod() {
        return 'lastschrift'
    }

    function getSetupIntent() {
        return new Promise((resolve, reject) => {

            let customer = getStripeCustomerID();

            fetchData('action=get_setup_intent&stripe_customer_id=' + customer).then(
                data => resolve(JSON.parse(data))
            ).catch(error => console.log(error));
        });
    }

    function emptyCustomer()  {
        return {
            "id": 0,
            "address": {
                "city": "Musterstadt",
                "country": "DE",
                "line1": "Musterstr 1",
                "line2": "",
                "postal_code": "00000",
                "state": ""
            },
            "name": "Kunde wird geladen",
            "invoice_settings": {
                "default_payment_method": "null"
            }
        }
    }

    function emptySubscriptionState()  {
        return {
            collection_method: '', //charge_automatically, or send_invoice.
            items: {
                price: ''
            }
        }
    }

    const adapter_object = {
        getStripeCustomer: getStripeCustomer,
        getPaymentMethod: getPaymentMethod,
        getSetupIntent: getSetupIntent,
        getCustomersPaymentMethods: getCustomersPaymentMethods,
        updateUsersDefaultPaymentMethod: updateUsersDefaultPaymentMethod,
        updateUsersDefaultPaymentMethodFromSelect: updateUsersDefaultPaymentMethodFromSelect,
        detachCustomersPaymentMethod: detachCustomersPaymentMethod,
        getVar: getQueryVariable,
        getPrices: getPrices,
        getCurrentSubscriptions: getCurrentSubscriptions,
        emptyCustomer: emptyCustomer,
        cancelSubscription: cancelSubscription,
        hasSubscriptions: hasSubscriptions,
        emptySubscriptionState: emptySubscriptionState,
        createSubscription: createSubscription,
        getCustomersInvoices: getCustomersInvoices,
        pk_stripe: pk_stripe
    }

    return adapter_object;
})();