import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: false,
}

export const bookedSlice = createSlice({
    name: 'booked',
    initialState,
    reducers: {
        booked: (state) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            state.value = true
        },
        unbooked: (state) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            state.value = false
        }
    },
})

// Action creators are generated for each case reducer function
export const { booked, unbooked } = bookedSlice.actions

export default bookedSlice.reducer