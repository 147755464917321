import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export const Messagebox = () => { 
   return (
    <Row>
        <Col className=''>
                <div className='alert d-none w-100' id='messagebox'></div> 
        </Col>
    </Row>
        
  )
}

 