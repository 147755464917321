import React, { useEffect, useState } from 'react';
import { Spinnercomponent } from '../Spinnercomponent';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';


export const Tabnav = ({handleCurrentTab}) => {
    return(
        <Navbar collapseOnSelect expand="lg"  bg="light" variant="light">
        <Container>
        <Navbar.Brand href="#home">Aboverwaltung</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="me-auto">
            <Nav.Link href="#home" onClick={e => handleCurrentTab(0)}>Profil</Nav.Link>
            <Nav.Link href="#payment" onClick={e => handleCurrentTab(1)} id="paymentlink">Bezahlmethoden</Nav.Link>
            <Nav.Link href="#products" onClick={e => handleCurrentTab(2)} id="productslink">Produkte</Nav.Link>
            <Nav.Link href="#invoices" onClick={e => handleCurrentTab(3)} id="invoiceslink">Rechnungen</Nav.Link>
        </Nav>
        </Navbar.Collapse>
        </Container>
        </Navbar>
    )

}



