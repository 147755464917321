import React, { useEffect, useState } from 'react';
import Alert from 'react-bootstrap/Alert';



export class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false, error: '', errorInfo:'' };
    }
  
    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }
  
    componentDidCatch(error, errorInfo) {
      // You can also log the error to an error reporting service
   //   logErrorToMyService(error, errorInfo);

   this.setState({...this.state, error: error, errorInfo: errorInfo});
    }
  
    render() {
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return  <Alert key={'danger'} variant={'danger'}>
        Es gab ein Problem mit dieser Aktion, bitte versuchen Sie es später noch einmal.
        <br />{this.props.message}<br />
        {this.state.error.message}
      </Alert>;
      }
  
      return this.props.children; 
    }
  }