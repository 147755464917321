import React, { useEffect, useState } from 'react';
import { Stripecontainer } from './components/Stripecontainer';
import { Spinnercomponent } from './components/Spinnercomponent';
import './App.css';

import 'bootstrap/dist/css/bootstrap.min.css';


import { useSelector, useDispatch } from 'react-redux'

import { get_stripe_customer } from './store/customerSlice'

export const App = (props) => {

   const[loaded, setLoaded] = useState(false);

   const customer = useSelector((state) => state.customer.value);
   const dispatch = useDispatch();


   /* use App to dispatch the get_stripe_customer asyncThunk and save the customer in the Customerslice */
   useEffect(
    () => {
      dispatch(get_stripe_customer()).
      then(data=>setLoaded(true));  
    },[customer.id]
   );


   if(!loaded){
    return <Spinnercomponent />
   }

   return (
         <Stripecontainer headline='Stripe Aboverwaltung mit React'/>
   )
}

