import React from 'react';
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner';

export const Spinnercomponent = (props) => {
     
    return (
        <Card className="w-100 text-center p-5">
            <Card.Body>
                <Card.Title>Loading</Card.Title>
                <Card.Text>
                    <Spinner as="span" animation="grow" variant="dark" />
                </Card.Text>
            </Card.Body>
        </Card>
    )
    
}
