import React, { useEffect, useState } from 'react';
import { Spinnercomponent } from '../Spinnercomponent';
import { Customersubscription } from './Customersubscription';
import {api} from '../../services/api';
import { renderMessage } from '../../services/stateServices';
import { togglePreloader } from '../../services/stateServices';
import {useSelector, useDispatch} from 'react-redux'
import { get_stripe_customer } from '../../store/customerSlice'
import { booked, unbooked } from '../../store/bookedSlice';

export const Customersubscriptions = ({headline, variant}) => {

    const [subscriptions, setSubscriptions] = useState({});
    const [loaded, setLoaded] = useState(false);
    const [customer_has_subscription, setCustomerSubscription] = useState(false);
    const dispatch = useDispatch();

    const booked_val = useSelector(state => state.booked.value)

    let subscriptions_return = "";

    useEffect(() => {
      
        api.getCurrentSubscriptions().then(
            sub_obj => {
                if(sub_obj.error === 1) {
                    renderMessage(sub_obj,'alert-danger');
                } else {
                    setSubscriptions(sub_obj);
                    if(sub_obj.data.length) {
                        setCustomerSubscription(true)
                    } else {
                        setCustomerSubscription(false)
                    }
                    setLoaded(true);
                }
     
            }
        ).catch(error => { 
            setSubscriptions(error);
        }); 
    },[loaded,customer_has_subscription, booked_val]);


    


    const cancelSubscription = (subscription) => {
        togglePreloader('on');
        setLoaded(false)    
        api.cancelSubscription(subscription.id).then(
             //update user in store
             dispatch(get_stripe_customer()). then(
                data=> {
                    dispatch(unbooked());
                    renderMessage({msg:`Das Abonnement mit der ID ${subscription.id} wurde erfolgreich gekündigt`},'Customersubscriptions','alert-success', false);
                    setLoaded(true);
                    togglePreloader('off');
                }
            )
        )
    }

    

    if(!loaded) {
        return <Spinnercomponent />
    } else {

       subscriptions_return = subscriptions.data.map(element => {
            return <Customersubscription subscription={element} key={element.id} cancelSubscription={cancelSubscription} variant={variant}/>
        })
        
    }

    if(!customer_has_subscription) {
        return null;
    } 
    
    return (
        <>
            <h3>{headline}</h3>
            {subscriptions_return}
        </>
    )
}