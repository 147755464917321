import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'bootstrap/dist/css/bootstrap.min.css';

export const Errorbox = () => { 
   return (
    <Row>
        <Col className=''>
                <div className='alert alert-warning d-none w-100' id='errorbox'></div> 
        </Col>
    </Row>
        
  )
}

 