import React, { useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

export const Customersubscription = ({subscription, cancelSubscription, variant}) => {

   let classes = variant === "default" ? "w-100 p-5 single-product-card alert border" : "w-100 p-5 single-product-card alert alert-success"; 
    

  return (
        <>
        <Card className={classes}>
            <Card.Body>
                <Card.Title>{subscription.plan.nickname}</Card.Title>
                <Card.Text>
                {subscription.plan.metadata.infoline}
                <br/><br/>
                <Button variant="warning" onClick={e => cancelSubscription(subscription)}>Kündigen</Button>
                </Card.Text>
            </Card.Body>
        
        </Card>

        
    </>
    )
}