import React, { useEffect, useState } from 'react';
import { Customersubscriptions } from '../customer/Customersubscriptions';
import { Spinnercomponent } from '../Spinnercomponent';
import { Invoice } from './Invoice';
import {api} from '../../services/api';
import { renderMessage } from '../../services/stateServices';


export const Invoices = ({headline}) => {

    const [invoices, setInvoices] = useState({});
    const [loaded, setLoaded] = useState(false);
    



    useEffect(() => {
        api.getCustomersInvoices().then(
            invoices => {
                if(invoices.error === 1) {
                    renderMessage({msg:'In der Komponente Invoices gab es einen Fehler beim Abruf der Rechnungen'},'alert-danger', false);
                } else {
                    setInvoices(invoices);
                    setLoaded(true);
                }
     
            }
        ).catch(error => { 
            setInvoices(error);
        }); 
    },[loaded]);


    
    let customer_invoices = [];


    if(!loaded) {
        return <Spinnercomponent />
    } else {
        customer_invoices = invoices.data.map(element => {
            return <Invoice invoice={element} key={element.id} />
        })
    }

    return (
        <>
          <h3>{headline}</h3>
         {customer_invoices}
        </>
    )
}