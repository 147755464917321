import React, { useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Booking } from '../Booking';
import {useSelector, useDispatch} from 'react-redux'
import { booked } from '../../store/bookedSlice';
import { unbooked } from '../../store/bookedSlice';
export const Product = ({price, disabled}) => {

    const [clicked, setClicked] = useState(false);
    const booked_val = useSelector((state) => state.booked.value)
    const dispatch = useDispatch();

    const handleBookingClick = (subscription) => {
        clicked ? setClicked(false) : setClicked(true);
    }

     

    return (
        <>
        <Card className='w-100 p-5 single-product-card'>
            <Card.Body>
                <Card.Title>{price.nickname}</Card.Title>
                <Card.Text>
                {price.metadata.infoline}
                <br/><br/>
                <Button variant="success" disabled={disabled} onClick={e => handleBookingClick(price.id)}>Buchen</Button>
                

                </Card.Text>
                <Booking headline={`Buchung ${price.nickname}`} visible={clicked} price={price}/>
            </Card.Body>
        
        </Card>

       

        
    </>
    )
}