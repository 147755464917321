import React, { useEffect, useState } from 'react';



export const Tabcontent = ({children, current_tab}) => {


    return(
       <>
        {children[current_tab]}
       </>
    )

}



