/**
 * 
 * @param {*} paymentmethod 
 * @returns a rendered string containing the payment method
 * 
 * param is a single stripe payment method object
 */
export const renderCustomerPaymentMethod = (paymentmethod, default_payment_method) => {
    let result = "";


    if (paymentmethod.type === 'card') {
        let type = 'Kreditkarte';
        let brand = paymentmethod.card.brand.toUpperCase();

        if (paymentmethod.id === default_payment_method) {
            type += ' (Standard)';
        }
        result += `${type}  ${brand} (..${paymentmethod.card.last4})`

        if (paymentmethod.card.wallet) {
            if (paymentmethod.card.wallet.type === 'google_pay') {
                result += '/GooglePay';
            }
        }
    }




    if (paymentmethod.type === 'sofort') {
        let type = 'Sofortüberweisung';

        if (paymentmethod.id === default_payment_method) {
            type += ' (Standard)';
        }
        result += `${type}`
    }

    if (paymentmethod.type == 'sepa_debit') {
        let type = 'Lastschrift';

        if (paymentmethod.id === default_payment_method) {
            type += ' (Standard)';
        }
        result += `${type} (..${paymentmethod.sepa_debit.last4})`
    }


    return result;
}



export const renderError = (error, component = 'App') => {
    let errorbox = document.getElementById('errorbox');

    if (errorbox) {
        errorbox.classList.remove('d-none');
        errorbox.innerHTML = `Es ist folgender Fehler in der Komponente ${component} aufgetreten: ${error.status} // ${error.msg}.`;
        errorbox.innerHTML += '<a href="#" id="errorclose">Schließen</a>';
    }
}



export const renderMessage = (info, msgclass = 'alert-info', hide_after_3sec = false) => {
    let messagebox = document.getElementById('messagebox');

    if (messagebox) {
        messagebox.classList.add(msgclass);
        messagebox.classList.remove('d-none');
        messagebox.innerHTML = `${info.msg}`;
        messagebox.innerHTML += '<a href="#" id="messageclose">Schließen</a>';

        hide_after_3sec && window.setTimeout(() => { document.getElementById('messagebox').classList.add('d-none'); }, 3000);
    }
}



export const triggerClick = (hash) => {
    let triggers = document.querySelectorAll('.nav-link');
    triggers.forEach(element => {
        if (element.getAttribute('href') === hash) {
            element.click()
        }
    });

}

/* used for the collection_method state in booking component */
export const switchOffBookingSwitches = (except) => {
    let form = document.querySelector('.booking-form');
    form.querySelectorAll('input[type="checkbox"]').forEach(
        element => {
            let element_id = element.getAttribute('id');
            if (element_id != except) {
                element.checked = false;
            }
        }
    );
}

export const togglePreloader = (action) => {
    if (action === 'on') {
        document.querySelector('#preloader').classList.remove('d-none')
    }
    if (action === 'off') {
        document.querySelector('#preloader').classList.add('d-none')
    }

}

document.addEventListener('click', e => {
    if (e.target.getAttribute('id') == 'errorclose') {
        document.getElementById('errorbox').classList.add('d-none');
    }
    if (e.target.getAttribute('id') == 'messageclose') {
        document.getElementById('messagebox').classList.add('d-none');
    }
})