import { adapter } from "./adapter";

export const api = (function() {

    const api_object = {
        getPaymentMethod: adapter.getPaymentMethod,
        getStripeCustomer: adapter.getStripeCustomer,
        getSetupIntent: adapter.getSetupIntent,
        getCustomersPaymentMethods: adapter.getCustomersPaymentMethods,
        pk_stripe: adapter.pk_stripe,
        updateUsersDefaultPaymentMethod: adapter.updateUsersDefaultPaymentMethod,
        detachCustomersPaymentMethod: adapter.detachCustomersPaymentMethod,
        updateUsersDefaultPaymentMethodFromSelect: adapter.updateUsersDefaultPaymentMethodFromSelect,
        getVar: adapter.getVar,
        getCurrentSubscriptions: adapter.getCurrentSubscriptions,
        getPrices: adapter.getPrices,
        emptyCustomer: adapter.emptyCustomer,
        emptySubscriptionState: adapter.emptySubscriptionState,
        hasSubscriptions: adapter.hasSubscriptions,
        cancelSubscription: adapter.cancelSubscription,
        createSubscription: adapter.createSubscription,
        getCustomersInvoices: adapter.getCustomersInvoices
    }
    adapter.updateUsersDefaultPaymentMethod();
    return api_object;
})();