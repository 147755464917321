import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

export const Preloader = (props) => {
     
    return (
        <div className="w-100 h-100 position-fixed d-none" id="preloader">
         
                    <Spinner as="span" animation="grow" variant="dark" />
             </div>
    )
    
}
