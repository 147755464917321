//RTK
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'


//CUSTOM
import { api } from '../services/api';
import { renderMessage } from '../services/stateServices';


const empty_customer = api.emptyCustomer();

const initialState = {
    value: empty_customer
};

const errorState = {
    value: {
        id: 0,
        error: 1,
        msg: 'Stripecustomer could not be received'
    }
};

//async thunk
export const get_stripe_customer = createAsyncThunk(
    'customer/getCustomer',

    async() => {
        let customer = await api.getStripeCustomer();
        let customers_pm = await api.getCustomersPaymentMethods();
        let has_subscription = await api.hasSubscriptions();

        return {
            ...customer,
            paymentmethods: customers_pm,
            has_subscription: has_subscription
        }

    }
);

export const customerSlice = createSlice({
    name: 'customer',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder
            .addCase(get_stripe_customer.fulfilled, (state, action) => {
                // Add user to the state
                state.value = action.payload;
                console.log('fulfilled')
            })
            .addCase(get_stripe_customer.rejected, (state, action) => {
                // Add user to the state
                state.value = errorState
            })
    },
})

// Action creators are generated for each case reducer function
export const { get_customer } = customerSlice.actions

export default customerSlice.reducer