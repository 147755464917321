import React, { useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { renderMessage } from '../services/stateServices';
import { api } from '../services/api';
import { togglePreloader } from '../services/stateServices';
import { switchOffBookingSwitches } from '../services/stateServices';
import {useSelector, useDispatch} from 'react-redux'
import { booked } from '../store/bookedSlice';


export const Booking = ({headline, visible, price}) => {
    
    const [subscription, setSubscription] = useState(api.emptySubscriptionState());
    const customer = useSelector((state) => state.customer.value);

    const dispatch = useDispatch();

    useEffect(()=> {
        console.log(customer)
        const new_state = {
            ...subscription,
            items : {
                price: price.id
            }
        }
        setSubscription(new_state);
    },[]);


    const handleCollectionMethodChange = (e) => {
        const collection_method = e.target.getAttribute('id');
        const new_state = {
            ...subscription,
            collection_method: collection_method
        }
        switchOffBookingSwitches(collection_method)
        setSubscription(new_state);
    }


    const bookNow = () => {
        togglePreloader('on');
        api.createSubscription(price, subscription.collection_method).then(
            data => { 
                renderMessage({msg: `Der Tarif ${price.nickname} wurde erfolgreich gebucht.`}, 'alert-success', false);
                togglePreloader('off');
                dispatch(booked());

            }
        )

    }


    const handlePaymentMethodClick = () => {
        document.getElementById('paymentlink').click();
    }

    if(!visible){
        return null;
    }

    if(customer.invoice_settings.default_payment_method === null)  {
        return (
            <>            <Form className='booking-form'>
                        <Form.Check 
                        type="switch"
                        id="send_invoice"
                        label="Rechnung erhalten"
                        onChange={handleCollectionMethodChange}
                    />
                    
            </Form>

            <a href="#payment" onClick={handlePaymentMethodClick}>oder Standardbezahlmethode festlegen</a>
            </>

    )
    }

    return (
            <Form className='booking-form'>
                {console.log('sub', subscription)}
                <Form.Check 
                        type="switch"
                        id="charge_automatically"
                        label="Standardbezahlmethode verwenden"
                        onChange={handleCollectionMethodChange}
                    />

                <Form.Check 
                        type="switch"
                        id="send_invoice"
                        label="Rechnung erhalten"
                        onChange={handleCollectionMethodChange}
                    /><br/>
                 <Button variant="info" size="sm" onClick={bookNow}>Verbindlich buchen</Button>   
            </Form>
    )
    
}
